import React from "react";
import { graphql } from "gatsby";
import Feedback from "../../components/feedback";
import LayoutController from '../../components/LayoutController'

import "./style.scss";

const FeedbackPage = props => {

  return (
    <LayoutController  title="Kierratys.info" currentPath={props.location.pathname}>
      <div className="feedbackPage">
        <div className="feedbackPageContainer">
        <Feedback heading="h1"/> 
      </div>
    </div>
    </LayoutController>
  )
};

export default FeedbackPage;

export const pageQuery = graphql`
query dummyQuery {
  allKivo (filter: {id: {eq: "dummy"}}) {
    edges {
      node {
        id
      }
    }
  }
}
`;
